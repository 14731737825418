import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react-lite';
import {Label} from 'semantic-ui-react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import {useHistory, useLocation} from 'react-router-dom';
import {useResizeDetector} from 'react-resize-detector';
import {AppContext} from '../../../store';
import ImageLazy from '../../../ui/ImageLazy';
import AttendeeButtons from '../AttendeeButtons';
import attendeeImg from '../../../assets/images/defaultImages/attendee.png';
import companyImg from '../../../assets/images/defaultImages/company-logo.png';
import './AttendeeCard.scss';

const AttendeeCard = observer(props => {
  const {attendee, openAttendee, isViewed} = props;
  const {participantStore, eventStore, exhibitorStore} = React.useContext(AppContext);
  const {tags, segment} = participantStore;
  const {eventDetails} = eventStore;
  const history = useHistory();
  const location = useLocation();
  const attendeeBooth = exhibitorStore.view.exhibitorsMap[attendee.boothId];
  const {width, ref} = useResizeDetector();
  const attendeeCardClasses = classNames('attendee-card', {'attendee-card--viewed': isViewed});

  const classesFav = a =>
    classNames('btn-favorite button', {
      'bookmark icon': tags[a.id] !== undefined && tags[a.id].fav,
      'bookmark outline icon': tags[a.id] === undefined || !tags[a.id].fav,
    });

  return !isEmpty(attendee) ? (
    <div className={attendeeCardClasses} ref={ref}>
      <div className="attendee-card__wrapper">
        <div className="attendee-card__top">
          <div className="attendee-card__img" onClick={openAttendee} aria-hidden>
            <Fragment>
              {attendee.profilePhoto ? (
                <ImageLazy
                  src={attendee.profilePhoto}
                  errorSrc={attendeeImg}
                  alt={attendee.name}
                  onClick={openAttendee}
                  width={80}
                  height={80}
                />
              ) : (
                <img aria-hidden src={attendeeImg} alt={attendee.name} onClick={openAttendee} width={80} height={80} />
              )}
              {eventDetails?.eventType === 'hybrid' && attendee.present ? (
                <span className="attendee-card__presence">
                  <FormattedMessage id={`attendee.card.presence.${attendee.present}`} />
                </span>
              ) : null}
            </Fragment>
          </div>
          <div className="attendee-card__info">
            <p className="attendee-card__label">
              <FormattedMessage id="attendee.card.label" />
            </p>
            <h4 className="attendee-card__name" onClick={openAttendee} aria-hidden>
              {(attendee.name ? `${attendee.name} ` : null) + (attendee.surname ? `${attendee.surname}` : null)}
              {segment === 'all' || segment === 'fav' ? <i aria-hidden className={classesFav(attendee)} /> : null}
              {segment === 'ignored' ? <i aria-hidden className="eye slash icon button" /> : null}
            </h4>
            <div className="attendee-card__company">
              {attendeeBooth ||
              attendee.jobTitle ||
              attendee.company ||
              (attendee.some && Object.keys(attendee.some).length > 0) ||
              attendee.participantType ? (
                <div className="attendee-card__det">
                  {attendeeBooth ? (
                    <div className="attendee-card__booth">
                      <ImageLazy
                        src={attendeeBooth.images?.logo || companyImg}
                        errorSrc={companyImg}
                        alt={attendeeBooth.name}
                        onClick={() =>
                          history.push({
                            pathname: `/event/${attendeeBooth.eventId}/expo/${attendeeBooth.id}`,
                            state: {
                              background: location.state ? location.state.background : location,
                              page: 'expo',
                              title: attendeeBooth.boothName,
                            },
                          })
                        }
                      />
                    </div>
                  ) : null}
                  <div className="attendee-card__small-det">
                    <div className="attendee-card__job">
                      {attendee.jobTitle ? attendee.jobTitle : null}
                      {attendee.jobTitle && attendee.company ? <span className="attendee-card__at">at</span> : null}
                      {attendee.company ? attendee.company : null}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {attendee.website ? (
              <div className="attendee-card__site">
                <a className="site_area" href={attendee.website} rel="noopener noreferrer" target="_blank">
                  {attendee.websiteDisplay}
                </a>
              </div>
            ) : null}
            {/* {!attendee.status ? (
              <div className="attendee-card__status">
                <Label className="attendee-card__status-label">at the venue</Label>
              </div>
            ) : null} */}
          </div>
        </div>
        {(attendee.some && Object.keys(attendee.some).length > 0) || attendee.participantType ? (
          <div className="attendee-card__label-more">
            {attendee.participantType ? (
              <div className="attendee-card__type">
                <Label
                  basic
                  className={classNames('attendee-card__type-label', {
                    [`attendee-card__type-label--${attendee.participantTypeConf.id}`]: attendee.participantTypeConf.id,
                  })}
                >
                  {attendee.participantType}
                </Label>
              </div>
            ) : null}
            {attendee.some ? (
              <div className="attendee-card__socials">
                {Object.keys(attendee.someLinks).map(social => {
                  if (attendee.some[social]) {
                    return (
                      <a
                        key={social}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={attendee.someLinks[social]}
                        className={`ui ${social} icon button`}
                      >
                        <i className={`${social} icon`} />
                      </a>
                    );
                  }
                  return null;
                })}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="attendee-card__bottom">
          <div className="attendee-card__offer">
            <div className="attendee-card__offer-desc">
              <h3>
                <FormattedMessage id="attendee.card.offer" />
              </h3>
              <p
                dangerouslySetInnerHTML={{__html: attendee.offer ? attendee.offer.replaceAll('\n', '<br>') : 'No info'}}
              ></p>
            </div>
          </div>
          <div className="attendee-card__seek">
            <div className="attendee-card__seek-desc">
              <h3>
                <FormattedMessage id="attendee.card.seek" />
              </h3>
              <p
                dangerouslySetInnerHTML={{__html: attendee.seek ? attendee.seek.replaceAll('\n', '<br>') : 'No info'}}
              ></p>
            </div>
          </div>
        </div>
        <AttendeeButtons attendee={attendee} width={width} />
      </div>
    </div>
  ) : null;
});

AttendeeCard.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  openAttendee: PropTypes.func,
  isViewed: PropTypes.bool,
};

AttendeeCard.defaultProps = {
  attendee: {},
  openAttendee: () => {},
  isViewed: false,
};

export default AttendeeCard;
