import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Button} from 'semantic-ui-react';
import {useHistory, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {AppContext} from '../../../store';
import {openChatMain} from '../../../services/helpers/chatHelper';
import messageImg from '../../../assets/images/icons/message.png';
import './AttendeeOpenChatBtn.scss';

const AttendeeOpenChatBtn = props => {
  const {attendee, openCallback, isShort, className, ...restProps} = props;
  const {userStore} = React.useContext(AppContext);
  const {user} = userStore;
  const history = useHistory();
  const location = useLocation();

  const classes = classNames(className, 'open-chat-btn', {
    'open-chat-btn--short': isShort,
  });

  return attendee && user.id !== attendee.userId && attendee.userId !== 'booth' ? (
    <Button
      className={classes}
      onClick={() => openChatMain(attendee, user, history, location, openCallback)}
      {...restProps}
    >
      {isShort ? (
        <img className="chat__icon" src={messageImg} alt="chat" />
      ) : (
        <FormattedMessage id="attendee.buttons.chat.btn" />
      )}
    </Button>
  ) : null;
};

AttendeeOpenChatBtn.propTypes = {
  attendee: PropTypes.oneOfType([PropTypes.object]),
  openCallback: PropTypes.func,
  isShort: PropTypes.bool,
};

AttendeeOpenChatBtn.defaultProps = {
  attendee: null,
  openCallback: () => {},
  isShort: false,
};

export default AttendeeOpenChatBtn;
