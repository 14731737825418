import React, {useContext, useState, useEffect, useRef} from 'react';
import {observer} from 'mobx-react-lite';
import {Button, Dimmer, Form, Loader, Segment} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../../store';
import QrScanner from '../../shared/QrScanner/QrScanner';
import CheckInContact from '../CheckInContact';
import CheckinStatusSelector from '../CheckinStatusSelector';
import {ZPL_PRINT_TYPE_OPTIONS} from '../CheckInZplPrintTypeSelector';
import NotificationAlert from '../../notifications-alert/NotificationAlert';
import {getLastSelectedCheckInData, handleScan} from '../../../services/helpers/checkInHelper';
import {printStandard, printTXT, printZPL, printZPLByPrintQueue} from '../../../services/helpers/printHelper';
import {getBadgePrintingStatus, markBadgeAsPrinted} from '../../../services/firebase/checkInFbApi';
import useBadges from '../../../hooks/useBadges';
import checkInConfig from '../checkInConfig';
import './CheckInScanner.scss';

const CheckInScanner = observer(() => {
  const {checkInStore, eventStore} = useContext(AppContext);
  const {eventDetails} = eventStore;
  const {scanTypeData, scannedContact} = checkInStore;
  const [checkInStatus, setCheckInStatus] = useState(true);
  const [status, setStatus] = useState('');
  const checkInStatusRef = useRef(checkInStatus);
  const {badge, isLoading: isLoadingBadge} = useBadges(eventDetails.defaultBadge);
  const badgeRef = useRef(badge);
  const selectedPrinter = getLastSelectedCheckInData(scanTypeData.eventId, 'printer') || {};

  useEffect(() => {
    checkInStatusRef.current = checkInStatus;
    badgeRef.current = badge;
  }, [checkInStatus, badge]);

  const scanner = () => (
    <QrScanner
      config={{fps: 10, qrbox: {width: 250, height: 250}}}
      qrAreaId="check-in-reader"
      className="check-in-scanner__scanner"
      scanThrottling={2000}
      scanFormats={scanTypeData.scanType === 'fast' && selectedPrinter.printType === 'TXT' ? ['CODE_128'] : []}
      qrCodeSuccessCallback={(scannedData, type) => {
        if (type === 'string') {
          setStatus('');
          handleScan(scannedData, checkInStatusRef.current).then(status => {
            const badge = badgeRef.current;
            const {scannedContact} = checkInStore;
            const selectedPrinter = getLastSelectedCheckInData(scanTypeData.eventId, 'printer');
            const badgeTpl = badge.type !== 'txt' ? badge.templates['BadgeFrontCanvas.template'] : null;

            if (status !== 'checkedIn' || !scannedData) {
              return;
            }

            getBadgePrintingStatus(eventDetails.id, scannedData).then(snapshot => {
              const data = snapshot.val();
              const isPrinted = data?.printed;

              if (isPrinted) {
                return NotificationAlert.warning(<FormattedMessage id="checkIn.container.badgeAlreadyPrinted.error" />);
              }

              if (selectedPrinter.printType === 'Standard') {
                printStandard(badgeTpl, scannedContact)
                  .then(() => {
                    console.log('standard text successfully printed');
                    markBadgeAsPrinted(eventDetails.id, scannedContact.ticketId, true);
                    return NotificationAlert.success(<FormattedMessage id="checkIn.container.badgePrinted.success" />);
                  })
                  .catch(error => console.log('standard print failed', error));
              }

              if (
                selectedPrinter.printType === 'ZPL' &&
                selectedPrinter.zplPrintType === ZPL_PRINT_TYPE_OPTIONS[0].value
              ) {
                printZPL(badgeTpl, scannedContact, selectedPrinter.ip)
                  .then(() => {
                    console.log('zpl code successfully printed');
                    markBadgeAsPrinted(eventDetails.id, scannedContact.ticketId, true);
                    return NotificationAlert.success(<FormattedMessage id="checkIn.container.badgePrinted.success" />);
                  })
                  .catch(error => console.log('zpl code print failed', error));
              }

              if (
                selectedPrinter.printType === 'ZPL' &&
                badgeTpl &&
                selectedPrinter.zplPrintType === ZPL_PRINT_TYPE_OPTIONS[1].value
              ) {
                return printZPLByPrintQueue(
                  eventDetails.id,
                  scannedContact.ticketId,
                  selectedPrinter.ip,
                  selectedPrinter.label,
                )
                  .then(status => {
                    if (status === 'isPrinting') {
                      return NotificationAlert.warning(
                        <FormattedMessage id="checkIn.container.badgeZPLIsPrinting.error" />,
                      );
                    }
                    NotificationAlert.success(
                      <FormattedMessage id="checkIn.container.badgeZPLIsPrinting.sentToPrint.success" />,
                    );
                    console.log('zpl code successfully printed by print queue');
                  })
                  .catch(error => console.log('zpl code by print queue failed', error));
              }
            });

            if (selectedPrinter?.printType === 'TXT' && scannedData && checkInStatusRef.current) {
              printTXT(scannedData, scanTypeData.eventId).then(isPrinted => {
                if (isPrinted) {
                  return NotificationAlert.success(<FormattedMessage id="checkIn.container.badgePrinted.success" />);
                }
                NotificationAlert.warning(<FormattedMessage id="checkIn.container.badgeAlreadyPrinted.error" />);
              });
            }

            Object.values(checkInConfig.requestStatuses).includes(status) && setStatus(status);
          });
        } else {
          NotificationAlert.error(<FormattedMessage id="checkIn.container.scan.data.error" />);
        }
      }}
    />
  );

  if (isLoadingBadge && !badge) {
    return (
      <Segment>
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    );
  }

  const checkInSelector =
    scanTypeData.scanType === 'fast' ? (
      <Form>
        <Form.Field>
          <CheckinStatusSelector onChangeStatus={setCheckInStatus} status={checkInStatus} />
        </Form.Field>
      </Form>
    ) : null;

  const lastScannedContact =
    scanTypeData.scanType === 'fast' && scannedContact && Object.keys(scannedContact).length ? (
      <CheckInContact contact={scannedContact} checkInStatus={status} />
    ) : null;

  const buttons = (
    <div className="check-in-scanner__btns">
      <Button color="blue" onClick={() => checkInStore.backToInitStep(scanTypeData)}>
        <FormattedMessage id="checkIn.scanner.back.btn" />
      </Button>
    </div>
  );

  return (
    <div className="check-in-scanner">
      <h3>
        <FormattedMessage id="checkIn.scanner.title" />
      </h3>
      {scanner()}
      {checkInSelector}
      {lastScannedContact}
      {buttons}
    </div>
  );
});

export default CheckInScanner;
