import React from 'react';
import {Switch, Route, Link, useParams} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {Dimmer, Loader} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {AppContext} from '../../store';
import Login from '../../pages/Login';
import Registration from '../../pages/Registration';
import PreRegistration from '../../pages/PreRegistration';
import ForgotPassword from '../../pages/ForgotPassword';
import ForgotPasswordConfirmation from '../../pages/ForgotPasswordConfirm';
import EventDescriptionPreLogin from '../../components/shared/EventDescriptionPreLogin';
import CarouselDealroom from '../../components/shared/CarouselDealroom';
import {ReactComponent as DealroomLogoImg} from '../../assets/images/dealroom_logo.svg';
import './PreLoginLayout.scss';

const PreLoginLayout = observer(() => {
  const {eventStore} = React.useContext(AppContext);
  const [event, setEvent] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const {page} = useParams();
  const siteName = window.location.hostname;
  const siteFirstName = siteName.split('.')[0];

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production' && !['qa', 'letsgo', 'dealroomevent-stage'].includes(siteFirstName)) {
      setLoading(true);
      eventStore
        .loadDomainDetails()
        .then(data => {
          setEvent(data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const router = () => (
    <Switch>
      <Route path="/login" render={() => <Login event={event} />} exact />
      <Route path="/registration" render={() => <Registration event={event} />} exact />
      <Route path="/preregister" render={() => <PreRegistration event={event} />} exact />
      <Route path="/forgot-password" render={() => <ForgotPassword event={event} />} exact />
      <Route path="/forgot-password-confirm" render={() => <ForgotPasswordConfirmation event={event} />} exact />
    </Switch>
  );

  const content = event ? (
    <div className="main main--event">
      <div className="main-auth">
        <div className="main-auth__aside">
          <EventDescriptionPreLogin event={event} />
        </div>
        <div className="main-auth__main">
          <div className="main-auth__header">
            <div className="main-auth__logo">
              <DealroomLogoImg />
            </div>
            <div className="main-auth__registration">
              {page === 'registration' ? (
                <Link to="/login">
                  <FormattedMessage id="pre.Login.Decorator.login" />
                </Link>
              ) : (
                <Link to="/registration">
                  <FormattedMessage id="pre.login.decorator.register" />
                </Link>
              )}
            </div>
          </div>
          {router()}
          <div className="main-auth__footer">
            <Link to="/privacy-notice" className="login-page__footer-link">
              <FormattedMessage id="app.footer.legal.privacy" />
            </Link>
            <span>•</span>
            <Link to="/terms" className="login-page__footer-link">
              <FormattedMessage id="app.footer.legal.terms" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="main main--dealroom">
      <div className="main-auth">
        <div className="main-auth__aside">
          <CarouselDealroom />
        </div>
        <div className="main-auth__main">
          <div className="main-auth__header">
            <div className="main-auth__logo">
              <DealroomLogoImg />
            </div>
            <div className="main-auth__registration">
              {page === 'registration' ? (
                <Link to="/login">
                  <FormattedMessage id="pre.Login.Decorator.login" />
                </Link>
              ) : (
                <Link to="/registration">
                  <FormattedMessage id="pre.login.decorator.register" />
                </Link>
              )}
            </div>
          </div>
          {router()}
          <div className="main-auth__footer">
            <div className="main-auth__footer">
              <Link to="/privacy-notice" className="login-page__footer-link">
                <FormattedMessage id="app.footer.legal.privacy" />
              </Link>
              <span>•</span>
              <Link to="/terms" className="login-page__footer-link">
                <FormattedMessage id="app.footer.legal.terms" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader inverted />
      </Dimmer>
    );
  }

  return content;
});

export default PreLoginLayout;
