import React, {useState, useEffect, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {FormattedMessage, injectIntl, useIntl} from 'react-intl';
import {AppContext} from '../../../store';
import {Button, Form, Checkbox, Dropdown, Message} from 'semantic-ui-react';
import CheckinPrinterSelector from '../CheckinPrinterSelector';
import {CheckInZplPrintTypeSelector, ZPL_PRINT_TYPE_OPTIONS} from '../CheckInZplPrintTypeSelector';
import useBadgePrinters from '../../../hooks/useBadgePrinters';
import {
  getLastSelectedCheckInData,
  setLastSelectedCheckInData,
  setScanTypeDataByArea,
} from '../../../services/helpers/checkInHelper';
import './CheckInForm.scss';

const CheckInForm = observer(() => {
  const intl = useIntl();
  const {eventStore, checkInStore, modalStore} = useContext(AppContext);
  const {areaList, scanTypeData} = checkInStore;
  const {eventDetails} = eventStore;

  const {filteredPrinterList, currentPrinter} = useBadgePrinters(eventDetails?.printers, eventDetails?.id, null, true);
  const showPrinterSelector = !!filteredPrinterList.length && eventDetails?.id;
  const [selectedPrinter, setSelectedPrinter] = useState('');
  const [selectedZplPrintType, setSelectedZplPrintType] = useState('');
  const [scanType, setScanType] = useState(scanTypeData.scanType || 'individual');
  const [scanMethod, setScanMethod] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [selectedArea, setSelectedArea] = useState('');

  useEffect(() => {
    if (!scanMethod && eventDetails) {
      const savedScanMethod = getLastSelectedCheckInData(eventDetails.id, 'scanMethod');
      setScanMethod(savedScanMethod || scanTypeData.scanMethod || 'camera');
    }
  }, [scanMethod, eventDetails]);

  useEffect(() => {
    if (!selectedEvent) {
      if (scanTypeData.eventId) {
        setSelectedEvent(scanTypeData.eventId);
      } else if (eventDetails && !eventDetails.isFinished) {
        setSelectedEvent(eventDetails.id);
      }
    } else {
      checkInStore.getAreaList(selectedEvent);
    }
  }, [selectedEvent, eventDetails]);

  useEffect(() => {
    if (areaList.length && !selectedArea) {
      setSelectedArea(areaList.find(area => area.id === 'allEvent')?.id || '');
    }

    if (!selectedPrinter && currentPrinter.ip) {
      setSelectedPrinter(currentPrinter.ip);
    }

    if (!selectedZplPrintType && currentPrinter.ip) {
      setSelectedZplPrintType(currentPrinter.zplPrintType ?? ZPL_PRINT_TYPE_OPTIONS[0].value);
    }
  }, [areaList, currentPrinter]);

  const areaSelectOptions = areas => {
    return areas.map(a => {
      return {
        key: a.id,
        flag: <span className="check-in-form__field--area-name">{a.name}</span>,
        text:
          a.capacity === a.capacityUsed &&
          a.id !== 'allEvent' &&
          intl.formatMessage({id: 'checkIn.form.area.fully.label'}),
        value: a.id,
      };
    });
  };

  const eventSelectOptions = events => {
    return events.map(e => {
      return {
        key: e.id,
        text: e.name,
        value: e.id,
      };
    });
  };

  const scanMethodOptions = [
    {
      text: 'Scan by camera',
      value: 'camera',
    },
    {
      text: 'Scan by handheld scanner',
      value: 'handheldScanner',
    },
  ];

  const handleScan = () => {
    const scanTypeDataByArea = setScanTypeDataByArea(selectedArea, selectedEvent, scanType, scanMethod);
    if (scanTypeDataByArea) {
      checkInStore.setScanTypeData({...scanTypeDataByArea});
      checkInStore.setMode(scanMethod === 'camera' ? 'scan' : 'handheldScan');
    }
  };

  const buttons = (
    <div className="check-in-form__btns">
      <Button color="blue" onClick={() => modalStore.close('checkInQrModal')}>
        <FormattedMessage id="checkIn.form.close.modal.btn" />
      </Button>
      <Button color="blue" disabled={!scanType || !selectedEvent} onClick={() => handleScan()}>
        <FormattedMessage id="checkIn.form.confirm.btn" />
      </Button>
    </div>
  );

  return (
    <div className="check-in-form">
      <h3>
        <FormattedMessage id="checkIn.form.title" />
      </h3>
      <Form>
        <Form.Field className="check-in-form__field--scan-type">
          <Checkbox
            radio
            label="Individual scanning"
            name="scanType"
            value="individual"
            checked={scanType === 'individual'}
            onChange={(e, data) => setScanType(data.value)}
          />
          <Message info>
            <FormattedMessage id="checkIn.form.individualScan.info" />
          </Message>
        </Form.Field>
        <Form.Field className="check-in-form__field--scan-type">
          <Checkbox
            radio
            label="Fast scanning"
            name="scanType"
            value="fast"
            checked={scanType === 'fast'}
            onChange={(e, data) => setScanType(data.value)}
          />
          <Message info>
            <FormattedMessage id="checkIn.form.fastScan.info" />
          </Message>
        </Form.Field>
        {showPrinterSelector ? (
          <>
            <Form.Field className="check-in-form__field--printer">
              <CheckinPrinterSelector
                value={selectedPrinter}
                onChange={setSelectedPrinter}
                printers={filteredPrinterList}
                eventId={eventDetails.id}
              />
            </Form.Field>
            {currentPrinter.printType === 'ZPL' && (
              <Form.Field className="check-in-form__field--zpl-print-type">
                <CheckInZplPrintTypeSelector
                  value={selectedZplPrintType}
                  onChange={value => setSelectedZplPrintType(value)}
                  eventId={eventDetails.id}
                  printer={currentPrinter}
                />
              </Form.Field>
            )}
          </>
        ) : null}
        <Form.Field className="check-in-form__field--scan-method">
          <label htmlFor="scan-method">
            <FormattedMessage id="checkIn.form.chooseScanMethod.label" />
          </label>
          <Dropdown
            selection
            placeholder={intl.formatMessage({id: 'checkIn.form.chooseScanMethod.placeholder'})}
            options={scanMethodOptions}
            id="scan-method"
            onChange={(e, {value}) => {
              setScanMethod(value);
              setLastSelectedCheckInData(eventDetails?.id, 'scanMethod', value);
            }}
            value={scanMethod}
          />
        </Form.Field>
        <Form.Field className="check-in-form__field--event">
          <label htmlFor="event">
            <FormattedMessage id="checkIn.form.chooseEvent.label" />
          </label>
          {eventDetails ? (
            <Dropdown
              selection
              placeholder={intl.formatMessage({id: 'checkIn.form.chooseEvent.placeholder'})}
              options={eventSelectOptions([eventDetails])}
              id="event"
              onChange={(e, {value}) => {
                setSelectedEvent(value);
              }}
              value={selectedEvent}
            />
          ) : null}
        </Form.Field>
        {selectedEvent ? (
          <Form.Field className="check-in-form__field--area">
            <label htmlFor="area">
              <FormattedMessage id="checkIn.form.chooseArea.label" />
            </label>
            <Dropdown
              selection
              placeholder={intl.formatMessage({id: 'checkIn.form.chooseEvent.placeholder'})}
              options={areaSelectOptions(areaList)}
              id="area"
              onChange={(e, {value}) => {
                setSelectedArea(value);
              }}
              value={selectedArea}
            />
          </Form.Field>
        ) : null}
      </Form>
      {buttons}
    </div>
  );
});

export default injectIntl(CheckInForm);
